import { Store } from "./main";
import { auth } from "@/assets/ts/firebase";

import { User as FirebaseUser } from "firebase/auth";

interface UserState extends Object {
  user: FirebaseUser | null;
  initialized: boolean;
  uid: string;
}

class UserStore extends Store<UserState> {
  protected data(): UserState {
    return {
      user: null,
      initialized: false,
      uid: "",
    };
  }

  async logout() {
    return await auth.signOut();
  }

  async setUser(user: FirebaseUser | null) {
    this.state.user = user;
    this.state.uid = user?.uid || "";
    this.state.initialized = true;
  }

  async refreshUserToken() {
    await auth.currentUser?.getIdToken(true); // For claims
    await auth.currentUser?.reload(); // For profile
    await this.setUser(auth.currentUser);
  }

  async waitForInitialization() {
    while (!this.state.initialized) {
      await new Promise((r) => setTimeout(r, 5));
    }
  }
}

const userStore: UserStore = new UserStore();

export default userStore;

auth.onAuthStateChanged((user) => userStore.setUser(user));
