import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

var app = createApp(App);

import mitt from "mitt";
app.provide("emitter", mitt());

import "@/assets/scss/main.scss";

app.use(router).mount("#app");
