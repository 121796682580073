import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import userStore from "@/store/userStore";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/dashboard",
    component: () => import(/* webpackChunkName: "dashboard-layout" */ "../views/dashboard/DashboardLayout.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import(/* webpackChunkName: "home" */ "../views/dashboard/Home.vue"),
      },
      {
        path: "/thanks",
        name: "Thanks",
        component: () => import(/* webpackChunkName: "thanks" */ "../views/dashboard/Thanks.vue"),
      },
    ],
  },
  {
    path: "/auth",
    component: () => import(/* webpackChunkName: "auth-layout" */ "../views/auth/AuthLayout.vue"),
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: "/authenticate",
        name: "Authenticate",
        component: () => import(/* webpackChunkName: "authenticate" */ "../views/auth/Authenticate.vue"),
      },

      {
        path: "/callback",
        name: "Callback",
        component: () => import(/* webpackChunkName: "callback" */ "../views/auth/Callback.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  await userStore.waitForInitialization();
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (userStore.getState().user == null) return next("/authenticate");
  }
  return next();
});

export default router;
