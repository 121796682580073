import { initializeApp } from "firebase/app";
import { getAuth, GithubAuthProvider, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCB92zQEU2Xnh16Dn39HADBHPzP6ktClTY",
  authDomain: "patreon-linker.firebaseapp.com",
  projectId: "patreon-linker",
  storageBucket: "patreon-linker.appspot.com",
  messagingSenderId: "674127260375",
  appId: "1:674127260375:web:f79f9ec5035e85797a9e92",
};

const app = initializeApp(firebaseConfig);

const a = getAuth();
const f = getFunctions();
const s = getFirestore();

const provider = new GithubAuthProvider();

if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
  connectAuthEmulator(a, "http://localhost:10001");
  connectFunctionsEmulator(f, "localhost", 10002);
  connectFirestoreEmulator(s, "localhost", 10003);
}

export const auth = a;
export const functions = f;
export const firestore = s;
export const githubProvider = provider;
